*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    margin: 0;
    background-color: #121212;
    -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4 ol,
ul,
li,
p,
dl,
dd {
    margin: 0;
    padding: 0;
}

iframe {
    z-index: -1 !important;
}

#root {
    width: 100%;
    height: 100%; /* this centers the Page component in the 100vh of body */
}

#root .head-less {
    height: 100vh;
}

/* width */
::-webkit-scrollbar {
    width: 0.7rem;
}

/* Track */
::-webkit-scrollbar-track {
    background: #121212;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 0.35rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

::-moz-selection {
    /* Code for Firefox */
    color: #40280b;
    background: #ee7127;
}

::selection {
    color: #40280b;
    background: #ee7127;
}

svg {
    pointer-events: none;
}

svg:has(.recharts-pie) {
    pointer-events: initial;
}

.recharts-pie .recharts-sector {
    transition: all 0.1s ease-out;
    cursor: pointer;
}
