@font-face {
    font-family: Roboto;
    font-weight: 300;
    src: url("../fonts/Roboto/Roboto-Light.ttf");
}

@font-face {
    font-family: Roboto;
    font-weight: 400;
    src: url("../fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
    font-family: Roboto;
    font-weight: 500;
    src: url("../fonts/Roboto/Roboto-Medium.ttf");
}

@font-face {
    font-family: Roboto;
    font-weight: 700;
    src: url("../fonts/Roboto/Roboto-Bold.ttf");
}
